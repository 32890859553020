.card {
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  /* Ensure the card fills the entire width of its parent container */
  height: 63vh !important;
  margin-top: 1.4rem;
  background-image: url('../../assets/1.png');
  background-size: fill;
  background-position: center;
  background-repeat: no-repeat;
  place-items: center; 
  background-color: transparent; 
  padding: 0;
  margin: 0;
  /* margin-top: 2rem; */
  background-size: 80% auto !important;
  /* margin-top: 3rem !important; */
  margin-top: 2rem;
  margin-bottom: 6rem !important;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0rem 0rem;
  padding-left: 0rem;
  background-color: transparent !important;
  margin-bottom: 6rem;
  /* margin-left: 0rem; */
}

.haiding {
  color:  #f28aee;;
  font-weight: 600;
  font-size: 2.8rem;
  /* font-size: 4vw; */
  letter-spacing: 0.3px;
  margin-bottom: 1rem;
  background-color: transparent;
  margin-left: -2.4rem;
}


.detail {
  color: white;
  font-size: 1rem;
  font-weight: 100 !important;
  font-size: 1.4rem;
  background-color: transparent;
  margin-bottom: 4rem;
  margin-left: -2.4rem;

}

@media  (min-width: 1820px) and (max-width: 2560px ) {

  .card {
    height: 50vh !important;
    background-size: 86% auto !important;
  }
  .haiding{
    margin-left: -3.3rem;
  }
  .detail{
    margin-left: -3.3rem;
  }
}


@media(min-height:661px) and (max-height:900px) {
  .card {
    background-size: contain;
    height: 60vh;
    width: 100%;
  }
}

/* @media(min-height:900px) {
  .card {
    background-size: contain;
    height: 40vh;
    width: 100%;
  }
} */

@media (max-height: 660px) {
  .card {
    background-size: contain;
    height: 60vh;
    width: 100%;

  }
}



@media (max-width: 793px) {

  .card,
  .left,
  .right,
  .haiding,
  .detail {
    padding: 0;
    margin: 0;
    gap: 0;
  }

  .card {
    background-size: contain;
    /* Adjust to contain when height is smaller */
    height: 60vh;
    width: 100%;
  }
  .haiding {
    font-size: 2rem;
    margin: 0;
    padding: 0;
  }

  .detail {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 50%;
  }



}
@media (min-width: 1024px) and (max-width: 1200px) {
  .haiding {
    color:  #f28aee;;
    font-weight: 600;
    font-size: 2.4rem;
    letter-spacing: 0.3px;
    margin-bottom: 2rem;
    padding-left: 7rem;
    margin-bottom: 1rem;
  }
  
  .detail {
    color: white;
    font-size: 1rem;
    margin-bottom: 10rem;
    font-size: 1.3rem;
    font-weight: 100 !important;
    padding-left: 7rem;

  }
  
}
@media (min-width: 1298px) and (max-width: 1366px) and (max-height: 700px) { 
  .card {
    background-size: contain;
    height: 75vh !important;
    width: 100%;
    margin-top: 8rem !important;
  }
  .haiding {
    color:  #f28aee;;
    font-weight: 600;
    font-size: 2.4rem;
    letter-spacing: 0.3px;
    margin-bottom: 2rem;
    padding-left: 7rem;
    margin-bottom: 1rem;
  }
  
  .detail {
    color: white;
    font-size: 1rem;
    margin-bottom: 10rem;
    font-size: 1.3rem;
    font-weight: 100 !important;
    padding-left: 7rem;

  }
  
}



@media (min-width: 1920) and (min-height: 1080px){
  .card{
    background-size: 80% auto !important;
    height: 79vh !important;
    background-size: contain !important;
  }
  
}


@media (max-width: 425px) {

  .card {
    margin-top: 2rem;
    background-size: contain;
    height: 36vh !important;
    width: 100%;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    margin-top: 4rem !important;
    margin-bottom: 2rem !important;
  }
  .card {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 2rem;

  }
  .left{
    margin-bottom: 1rem;
  }

  .haiding {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
  }

  .detail {
    font-size: 0.8rem;
    margin: 0;
    padding: 0;
    word-wrap: break-word;
    overflow-wrap: break-word;
    max-width: 80%;
  }


}
.card {
  display: grid;
  grid-template-columns: 2fr 1fr;
  width: 100%;
  /* Ensure the card fills the entire width of its parent container */
  height: 70vh !important;
  margin-top: 1.4rem;
  background-image: url('../../assets/earthscblur.png');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  place-items: center;
  margin-top: 8rem !important;
  margin-bottom: 6rem !important;
  background-size: 85% auto !important;
  box-shadow: inset 0 0 150px rgba(0, 0, 0, 0.48), 
  0 0 80px rgba(0, 0, 0, 0.48);
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
  padding-left: 0rem;
  background-color: transparent !important;
}


.rHiding {
  color: white;
  font-size: 2.8rem;
  letter-spacing: 0.4px;
  font-weight: 700;
  display: inline-block;
  background-color: transparent;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  background-color: transparent !important;
}

.detail {
  max-width: 700px;
  font-size: 1.8rem;
  line-height: 1.2;
  font-weight: 100 !important;
  color: white;
  background-color: transparent;
  margin-top: 2rem;
}

.subCard {
  grid-column: 1 / -1;
  /* Span the full width of the grid */
  width: 100%;
  /* Ensure it takes up the full width */
  align-self: center;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  margin-top: -70px;
  margin-bottom: 10px;
  padding-top: 0px;
}

.subDetail {
  max-width: 700px;
  font-size: 1.8rem;
  line-height: 1.2;
  font-weight: 400;
  color: white;
  background-color: transparent;
  text-align: center;
  margin: 0 auto;
  margin-top: 11rem;
}

@media (min-width: 1024px) and (max-width: 1300px) {
  .card {
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 100%;
    /* Ensure the card fills the entire width of its parent container */
    height: 55vh !important;
    margin-top: 1.4rem;
    background-image: url('../../assets/earthsc.png');
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    place-items: center;
    background-size: 85% auto !important;
    /* background-size: cover !important; */
    /* background-position-x: 58% ; */
    /* box-shadow: inset 40px 0px 50px 29px rgba(0, 0, 0, 0.7); */
  
  }
  .rHiding {
    color: white;
    font-weight: 600;
    font-size: 2.4rem;
    letter-spacing: 0.3px;
  }
  
  .detail {
    color: white;
    font-size: 1rem;
    margin-bottom: 2rem;
    font-size: 1.3rem;
    font-weight: 100 !important;
    max-width: 65%;
  }

  .left {
    padding-left: 8rem;
  }
  .subDetail {
    margin-top: 4rem;
  }
  
}



@media(min-height:661px) and (max-height:900px) {
  .card {
    background-size: contain;
    height: 60vh;
    width: 100%;
  }
}

@media(min-height:900px) {
  .card {
    background-size: contain;
    height: 40vh;
    width: 100%;
  }
}

@media (max-height: 660px) {
  .card {
    background-size: contain;
    /* Adjust to contain when height is smaller */
    height: 60vh;
    width: 100%;

  }
}

@media  (min-width: 1820px) and (max-width: 2560px ) {
  .rHiding {
    margin-left: -2rem;
  }
  .detail{
    margin-left: -2rem;
  }
  .subDetail {
    margin: 0 auto;
    margin-top: -4rem;
  }
  .subCard {
    margin-top: 0rem;
  }
  .card {
    height: 35vh !important;
    margin-top: 1.4rem;
    background-size: 85% auto !important;
  }
  .left {
    padding: 10rem 0;

  }
}



@media (max-width: 793px) {

  .card,
  .action,
  .left,
  .right,
  .rDetail,
  .rHiding,
  .haiding,
  .detail {
    padding: 0;
    margin: 0;
    gap: 0;
  }

  .card {
    background-size: contain;
    /* Adjust to contain when height is smaller */
    height: 60vh;
    width: 100%;
  }

  .haiding {
    font-size: 1.8rem;
  }

  .subCard {
    margin-top: -50px;
  }

  .detail {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 1rem;
    /* text-align: justify; */
    margin-bottom: 2rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .rHiding {
    font-size: 2rem;
    padding: 0;
    text-align: left;
    margin-bottom: 2rem;
  }


}

@media (max-width: 425px) {

  .card {
    background-size: contain;
    height: 45vh !important;
    width: 100%;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    margin-bottom: 0rem;
    margin-top: 4rem !important;
    margin-bottom: 2rem !important;
  }


  .haiding {
    font-size: 1.8rem;
  }

  .subCard {
    margin-top: 0px;
  }

  .detail {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 1rem;
    /* text-align: justify; */
    margin-bottom: 2rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .rHiding {
    font-size: 1.8rem;
    padding: 0;
    text-align: left;
  }
  .left {
  
    max-width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    padding: 2rem 1rem;
    padding-left: 2rem;

  }

  .subDetail {
    font-size: 1rem;
    margin-top: 15px;
  }

  .detail {
    margin-top: 0px;
    font-size: 1rem;
    max-width: 70%;
  }

  .action1 {
    margin: 0 auto;
    padding: 1rem;
    /* display: block; */
    width: 150px;
    border-radius: 10px;
    border: none;
    outline: none;
    margin: 0 auto;
    font-size: 1.2rem;
  }
}
.card {
  background-color: #000000;
  padding: 1rem;
  color: white;
  display: flex;
  gap: 4rem;
  padding: 0rem 1rem;
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.inner {
  display: flex;
  align-items: center;
}
.right {
  display: flex;
  flex-direction: column;
  /* padding: 2rem 2rem; */
  align-items: flex-start;
  flex: 1;
  padding-left: 2rem;
}
.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
  padding-left: 5rem;
  flex-basis: 50.33%;
}


/* 
.left {
  display: flex;
  flex-direction: column;
  padding-left: 7.4rem;
  padding-top: -4rem;
  flex-basis: 70.33%;
  max-width: auto;
  background-color: yellow;
} */

.haiding {
  color:  #f28aee;;
  font-weight: 600;
  font-size: 2.8rem;
  letter-spacing: 0.3px;
  margin-bottom: 2rem;
}


.haiding2 {
  font-weight: 600;
  letter-spacing: 0.6px;
  font-size: 4rem;
  margin-bottom: 2rem;
  /* width: 100%; */
  text-align: start;
  color:  #f28aee;;
}

/* .detail {
  color: white;
  font-size: 1rem;

  margin-bottom: 2rem;
  font-size: 1.7rem;
} */
.detail {
  color: white;
  font-size: 1rem;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  font-weight: 100 !important;
  max-width: 90%;
}

.action {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
  text-align: center;

}

.action1 {
  background-color: #f28aee;
  padding: 1rem;
  display: block;
  width: 240px;
  border-radius: 10px;
  border: none;
  outline: none;
  margin: 0 auto;
  font-size: 1.2rem;
}

.action2 {
  border: none;
  outline: none;
  padding: 1rem;
  border-radius: 10px;
  background-color: white;
  color: black;
  width: 50%;
  display: block;
}

.action1,
.action2:hover {
  cursor: pointer;
}


.image1 {
  height: 150px;
  width: 150px;

}


.img1 {
  max-width: 100%;
  object-fit: contain;
  height: 150px;
  width: 150px;
  padding-top: 2rem;

}

.img3 {
  max-width: 100%;
  object-fit: contain;
  height: 350px;
  width: 350px;
}

.image3 {
  height: 350px;
  width: 350px;
}

/* .img2 {
  max-width: 100%;
  object-fit: contain;
  height: 140px;
  width: 140px;
}

.image2 {
  height: 140px;
  width: 140px;
  text-align: center;
  width: 100%;
} */

.img2 {
  max-width: 100%;
  object-fit: contain;
  height: 500px;
  width: 500px;
}

.image2 {
  height: 520px;
  width: 520px;
}



.bottomT {
  padding-top: 4rem;
  font-size: 1.4rem;
  color: white;
}

.coloredline {
  background-color: #f28aee;
  margin: 0 auto;
  width: 65%;
  height: 1px;
  margin-top: 5rem;
}

@media(max-width:756px) {
  .card,
  .action,
  .left,
  .right,
  .rDetail,
  .rHiding,
  .haiding,
  .detail {
    padding: 0;
    margin: 0;
    gap: 0;
  }
  .card {
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 1rem;
    padding: 2rem 1rem;
    padding-top: 6rem !important;
    padding-bottom: 2rem !important;
  }


  /* .card,
  .right,
  .left,
  .action2,
  .haiding,
  .haiding2,
  .detail {
    padding: 0;
    margin: 0;
    gap: 0;
    grid-template-columns: 1fr;
    padding: 0.6rem 0.3rem;
  } */


  .right,
  .left {
    display: block;
    gap: 0;
  }




  .inner {
    display: block;
    gap: 0;
    padding: 0;
    margin: 0;
  }

  /* .haiding {
    font-size: 2rem;
    margin-bottom: 2rem;
  } */
  .haiding {
    font-size: 1.8rem;
    margin: 0;
    padding: 0;
    margin-left: 1rem;
  }

  .haiding2 {
    font-size: 2.6rem;
    margin-bottom: 2rem;
    margin-left: 1rem;
  }

  /* .detail {
    font-size: 1rem;
    margin-bottom: 3rem;

  } */

 
  .action1{
    display: block;
    width: 100%;
    padding: 0.8rem;
    font-size: 1rem;
    font-weight: 400;
}
.action{
  padding: 0;
  margin: 0;
  gap: 0;
  width: 100%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.action1{
  display: block;
  width: 90%;
  padding: 0.8rem;
  font-size: 1rem;
  font-weight: 400;
}

  .image1,
  .img1 {
    height: 90px;
    width: 90px;
    margin: 0;
    padding: 0;
    margin-right: 5%;
  }

  /* .image3,
  .img3{
    height: 250px;
    width: 250px;
  }

  .image2 {
    width: 100%;
    margin-top: -10%;
  }

  .img2 {
    width: 80px;
    height: 80px;
  } */
  .img2 {
    max-width: 100%;
    object-fit: contain;
    height: 200px;
    width: 200px;
  }
  .image2 {
    height: 200px;
    width: 200px;
  }

  .inner {
    padding: 0;
    margin: 0;
    gap: 0;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
    justify-content: center;

  }




  .bottomT {
    width: 100%;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    font-size: 1rem;
    text-align: center;
    margin-top: 2rem;
    font-weight: 600;
    letter-spacing: 0.6px;
  }
  .detail {
    font-size: 1rem;
    margin: 0;
    padding: 0;
    margin-left: 1rem;
    margin-bottom: 2rem;
  }
  .image2{
    height: 400px;
    width: 400px;
  }
  .img2 {
    display: flex;
    max-width: 100%;
    height: 400px;
    width: 400px;
    align-self: center;
    justify-content: center;
    align-self: center;
  }

}
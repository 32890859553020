
.action {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  /* margin-left: 3rem; */
  margin: 10px 0px ;
  /* padding-left: 4rem; */
  height: 80px;
}

.imgBtn {
  background-color: #fff;
  width: 200px;
  height: 60px;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

@media (max-width: 756px) {
  .action {
    padding: 0;
    margin: 0;
    gap: 2rem;
    margin-top: 2rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 180px;
  }
}

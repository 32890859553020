.card {
  background-color: #000000;
  padding-left: 3rem;
  padding-right: 3rem;
  color: white;
  display: flex;
  gap: 4rem;
  padding-top: 6rem !important;
  padding-bottom: 6rem;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0rem 0;
  padding-left: 5rem;
  flex-basis: 50.33%;
}

.haiding {
  font-weight: 600;
  font-size: 3rem;
  margin-bottom: 2rem;
  letter-spacing: 0.6px;
  color: #f28aee;
}

.detail {
  max-width: 500px;
  font-size: 1.8rem;
  line-height: 1.6;
  font-weight: 100 !important;
}

.right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding-left: 2rem;
}

.users{
  display: flex;
  flex-direction: row;
  max-width: 85%;
  margin-top: 0rem;
}

.horizontalUsers {
  display: flex;
  overflow-x: auto; /* Horizontal scrolling */
}

.horizontalUser {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 20px;
  margin-top: 4rem;
}

.horizontalUserImage {
  width: 80%;
  height: 80%;
  border-radius: 50%;
  object-fit: cover;
}

.userName {
  font-size: 0.8rem;
  color: white;
  margin-top: 1rem;
  text-align: center;
}

.userTitle {
  margin-top: 1rem;
  text-align: center;
  color: white;
}

.rHiding {
  color:  #f28aee;;
  font-size: 3rem;
  letter-spacing: 0.4px;
  font-weight: 600;
  margin-top: 4rem;
}
.img2 {
  max-width: 100%;
  height: 400px;
  width: 640px;
}

.rDetail {
  font-size: 1.8rem;
  line-height: 1.6;
  
  /* align-self:center;
  text-align: center; */
  margin-top: 2rem;
  font-weight: 100 !important
}

.action {
  width: 100%;
  text-align: center;
  margin-top: 4rem;
}

.action1 {
  background-color: #f28aee;
  padding: 1rem;
  display: block;
  width: 240px;
  border-radius: 10px;
  border: none;
  outline: none;
  margin: 0 auto;
  font-size: 1.2rem;
}

.action1:hover {
  cursor: pointer;
}

.download-buttons-container {
  padding: 1rem 1rem 0 0;
}

@media (min-width: 1024px) and (max-width: 1300px) {
  .rHiding {
    color:  #f28aee;;
    font-size: 2.4rem;
    letter-spacing: 0.4px;
    font-weight: 600;
  }
  
  .detail {
    color: white;
    font-size: 1.3rem;
    width: 100%;
    font-weight: 100 !important;
  }
  
}

@media (max-width: 756px) {
  .card,
  .action,
  .left,
  .right,
  .rDetail,
  .rHiding,
  .haiding,
  .detail {
    padding: 0;
    margin: 0;
    gap: 0;
  }
  .card {
    flex-direction: column;
    gap: 1rem;
    padding: 2rem 1rem;
   
  }

  .haiding {
    font-size: 1.8rem;
    color:  #f28aee;
  }

  .img2 {
    max-width: 100%;
    height: 200px;
    width: 200px;
    align-self: center;
  }
  .detail {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 1rem;
    /* text-align: justify; */
    margin-bottom: 2rem;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }

  .rHiding{
    font-size: 2rem;
    padding: 0;
    text-align: left;
    margin-bottom: 2rem;
    color:  #f28aee;
  }

  .rDetail{
    font-size: 1rem;
    margin-bottom: 3rem;
  }

  .action1{
    display: block;
    width: 100%;
    padding: 0.8rem;
    font-size: 1rem;
    font-weight: 400;
}
.users{
  display: flex;
  flex-direction: row;
  max-width: 100%;
}

.horizontalUser {

  margin-top: 2rem;
  margin-bottom: 2rem;
}
.download-buttons-container {
  padding: 1rem 1rem 0 0;
  width: 100%;
}

}

.card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #000000;
  place-items: center;
  padding: 0rem 1rem;
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.left,
.right {
  width: 100%;
  padding: 0rem 2rem;
}

.right {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* padding-top: 8rem; */
}

.inner {
  display: flex;
  align-items: center;
  margin-bottom: 10rem;
  gap: 3rem;
}

.coloredline {
  background-color: #f28aee;
  margin: 0 auto;
  width: 65%;
  height: 1px;
  margin-top: 5rem;
}


.left {
  display: flex;
  flex-direction: column;
  padding-left: 7.4rem;
  /* padding-top: -4rem; */


}

.haiding {
  color:  #f28aee;;
  font-weight: 600;
  font-size: 2.8rem;
  letter-spacing: 0.3px;
  margin-bottom: 2rem;

}


.haiding2 {
  font-weight: 800;
  letter-spacing: 0.6px;
  font-size: 4rem;
  margin-bottom: 2rem;
  width: 100%;
  text-align: center;
}

.detail {
  color: white;
  font-size: 1rem;

  font-weight: 100 !important;
  margin-bottom: 2rem;
  font-size: 1.7rem;
}

.detail2 {
  color: white;
  font-size: 1rem;
  font-weight: 100;
  margin-bottom: 2rem;
  font-size: 1rem;
}

.action {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;

}

.action1 {
  background-color: #f28aee;
  padding: 1rem;
  display: block;
  width: 240px;
  border-radius: 10px;
  border: none;
  outline: none;
  margin: 0 auto;
  font-size: 1.2rem;
}

.action2 {
  border: none;
  outline: none;
  padding: 1rem;
  border-radius: 10px;
  background-color: white;
  color: black;
  width: 50%;
  display: block;
}

.action1,
.action2:hover {
  cursor: pointer;
}


.img3 {
  max-width: 100%;
  object-fit: contain;
  height: 600px;
  width: 600px;
  padding-bottom: 2rem;
  align-self: flex-start;
}

.image3 {
  padding-bottom: 2rem;
  height: 600px;
  width: 500px;

  justify-content: flex-start;
  justify-self: flex-start;
  justify-items: start;
}
@media (min-width: 1024px) and (max-width: 1300px) {
  .haiding {
    color:  #f28aee;;
    font-weight: 600;
    font-size: 2.4rem;
    letter-spacing: 0.3px;
    margin-bottom: 2rem;
  }
  
  .detail {
    color: white;
    font-size: 1rem;
    margin-bottom: 2rem;
    font-size: 1.3rem;
    font-weight: 100 !important;
  }
  .img3 {
   
    height: 400px;
    width: 400px;
  }
  
  .image3 {

    height: 500px;
    width: 400px;

  }


  
}





@media(max-width:756px) {

  .card,
  .right,
  .left,
  .action,
  .action2,
  .action,
  .haiding,
  .haiding2,
  .detail {
    padding: 0;
    margin: 0;
    gap: 0;
    grid-template-columns: 1fr;
    padding: 0.6rem 0.3rem;

  }
  .card{
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .right,
  .left {
    display: block;
    gap: 0;
  }

  .left{
    padding-left: 1.2rem;
  }



  .inner {
    display: block;
    gap: 0;
    padding: 0;
    margin: 0;
  }

  .haiding {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .haiding2 {
    font-size: 2.6rem;
    margin-bottom: 2rem;
  }

  .detail {
    font-size: 1rem;
    font-weight: 600;
    width: 100%;
    padding: 0;
    margin: 0;
    /* text-align: justify; */
    margin-bottom: 2rem;
    word-wrap: break-word;
    overflow-wrap: break-word;

  }

  .action1 {
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
  }


  .inner {
    padding: 0;
    margin: 0;
    gap: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image3,
  .img3 {
    width: 290px;
    height: 410px;
    padding-bottom: 0rem;
  }

  .bottomT {
    width: 100%;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    font-size: 1rem;
    text-align: left;
    margin-top: 2rem;
    font-weight: 600;
    letter-spacing: 0.6px;
  }

}

.card {
  display: flex; /* Use flexbox for the card layout */
  flex-direction: row;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.left,
.right {
  flex: 1; /* Make both left and right divs take up equal space */
  padding: 2rem; /* Add padding */
}

.right{
  padding-top: 10rem;
}


.pinkDivLeft{
  display: flex;
  flex: 1;
  background-color: #f28aee;
  width: 80%;
  height: 90%;
  align-items: center;
  justify-content: flex-start;
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 2rem;
  border-radius: 5%;
}

.pinkDivRight{
  display: flex;
  background-color: #f28aee;
  width: 80%;
  height: 100%;
  flex: 1;
  justify-content: flex-start;
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-radius: 5%;
  padding-left: 2rem;

}

.itemList{
  color: white;
  letter-spacing: 0.3px;
  line-height: 2.5rem;
  padding-left: 0rem;
  background-color: #f28aee;
}

.inner {
  display: flex;
  align-items: center;
}


.left {
  display: flex;
  flex-direction: column;
  padding-left: 7.4rem;
  padding-top: -4rem;
}

.haiding {
  color:  #f28aee;;
  font-weight: 600;
  font-size: 3rem;
  letter-spacing: 0.3px;
  margin-bottom: 2rem;
}

.action {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;

}

.action1 {
  background-color: #f28aee;
  padding: 1rem;
  display: block;
  width: 240px;
  border-radius: 10px;
  border: none;
  outline: none;
  margin: 0 auto;
  font-size: 1.2rem;
}

.action2 {
  border: none;
  outline: none;
  padding: 1rem;
  border-radius: 10px;
  background-color: white;
  color: black;
  width: 50%;
  display: block;
}

.action1,
.action2:hover {
  cursor: pointer;
}


.image1 {
  height: 150px;
  width: 150px;

}


.img1 {
  max-width: 100%;
  object-fit: contain;
  height: 150px;
  width: 150px;
  padding-top: 2rem;

}

.img3 {
  max-width: 100%;
  object-fit: contain;
  height: 350px;
  width: 350px;
}

.image3 {
  height: 350px;
  width: 350px;
}

.img2 {
  max-width: 100%;
  object-fit: contain;
  height: 140px;
  width: 140px;
}

.image2 {
  height: 140px;
  width: 140px;
  text-align: center;
  width: 100%;
}


.coloredline {
  background-color: #f28aee;
  margin: 0 auto;
  width: 65%;
  height: 1px;
  margin-top: 5rem;
}

@media(max-width:756px) {

  .card {
   display: flex;
   flex-direction: column;
  }

  .right,
  .left {
    display: flex;
    flex:1;
  }


.left {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding-left: 2rem;
  margin-left: 0rem;
}

  .right{
    max-width: 100%;
  }

  .pinkDivLeft{
    align-self: center;
    width: 100%;
    padding-left: 1rem;

  }
  .pinkDivRight{
    align-self: center;
    width: 100%;
    padding-left: 1rem;
  }

  .itemList{
    letter-spacing: 0.2px;
    line-height: 2rem;
    padding-left: 0rem;
    background-color: #f28aee;
  }



  .haiding {
    font-size: 2rem;
    margin-bottom: 2rem;
  }


}
.card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex:1;
  padding-bottom: 6rem !important;

  }

  .haiding{
    color:white;
    font-size: 1.8rem;
    font-weight: 400;
  }
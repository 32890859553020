

.card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  background-color: #000000;
  place-items: center;  
  padding: 0rem 1rem;
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.left,
.right {
  width: 100%;
    padding: 3rem 3rem;
}



.right{
    display: flex;
    align-items: center;
    flex-direction: column;
    
}

.inner{
    display: flex;
    align-items: center;
    gap: 2rem;
}


.left{
    display: flex;
    flex-direction: column;
  padding-left: 7rem;


}

.haiding {
  color:  #f28aee;;
  font-weight: 600;
  font-size: 2.8rem;

  letter-spacing: 0.3px;
  margin-bottom: 2rem; 
 
}

.detail {
  color: white;
  font-size: 1rem;
  margin-bottom: 2rem;
  font-size: 1.8rem;
  font-weight: 100 !important;
}


.action {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;
 
}

.action1 {
  background-color: #f28aee;
  padding: 1rem;
  display: block;
  width: 50%;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 1.1rem;
  font-weight: 400;
  font-family: inherit;
}

.action2 {
  border: none;
  outline: none;
  padding: 1rem ;
  border-radius: 10px;
  background-color: white;
  color: black;
  width: 50%;
  display: block;
    font-size: 1.1rem;
    font-weight: 400;
    font-family: inherit;
}

.action1,
.action2:hover {
  cursor: pointer;
}


.image1 {
  height: 190px;
  width: 190px;

}



.img1 {
  max-width: 100%;
  object-fit: contain;
  height: 220px;
  width: 220px;

}

.img3 {
  max-width: 100%;
  object-fit: contain;
  height: 240px;
  width: 240px;
}

.image3 {
  height: 240px;
  width: 240px;

}
.img2 {
  max-width: 100%;
  object-fit: contain;
  height: 500px;
  width: 500px;
}

.image2 {
  height: 520px;
  width: 520px;
}


@media(max-width:756px){
    .card{
        grid-template-columns: 1fr;
        
    }

    .img2 {
      max-width: 100%;
      object-fit: contain;
      height: 200px;
      width: 200px;
    }
    
    .image2 {
      height: 200px;
      width: 200px;
    }

    .left{
       
        max-width: 100%;
        padding: 0;
        margin: 0;
        overflow: hidden;
        padding: 2rem 1rem;
        gap: 2rem;
      
    }

    .right{
       
        margin: 0;
        padding: 0;
        padding: 0rem 1rem;
        padding-bottom: 2rem;
    }
    .action{
        flex-direction: column;
        margin: 0;
        padding: 0;
        gap: 0;
        gap: 1rem;
    }
    .action1,.action2{
        width: 100%;
        margin: 0;
        padding: 0.8rem;
        font-size: 0.8rem;
    
    }
    .haiding {
      font-size: 1.8rem;
      margin: 0;
      padding: 0;
    }
  
    .detail {
      font-size: 1rem;
      margin: 0;
      padding: 0;
    }
}
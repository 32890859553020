.card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0rem;
  background-color: #000000;
  place-items: center;
  padding: 0rem 1rem;
  padding-top: 0rem !important;
  padding-bottom: 4rem !important;
}

.left,
.right {
  width: 100%;
  padding: 2rem 2rem;
}

.right {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.inner {
  display: flex;
  align-items: center;
  /* gap: 2rem; */
}

.left {
  display: flex;
  flex-direction: column;
  padding-left: 7rem;
}

.haiding {
  color:  #f28aee;;
  font-weight: 600;
  font-size: 2.8rem;
  /* font-size: 4vw; */
  letter-spacing: 0.3px;
  margin-bottom: 2rem;
}


.detail {
  color: white;
  font-size: 1rem;   
   font-weight: 100 !important;

  margin-bottom: 2rem;
  font-size: 1.8rem;
}

.detail2 {
  color: white;
  font-size: 1rem;
  font-weight: 100 !important;

  margin-bottom: 2rem;
  font-size: 1rem;
}

.action {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2rem;

}

.action1 {
  background-color: #f28aee;
  padding: 1rem;
  margin-left: 4rem;
  display: block;
  width: 240px;
  border-radius: 10px;
  border: none;
  outline: none;
  font-size: 1.1rem;
  font-weight: 400;
  font-family: inherit;
}

.action2 {
  border: none;
  outline: none;
  padding: 1rem;
  border-radius: 10px;
  background-color: white;
  color: black;
  width: 50%;
  display: block;
  font-size: 1.1rem;
  font-weight: 400;
  font-family: inherit;
}

.action1,
.action2:hover {
  cursor: pointer;
}

.image1 {
  height: 190px;
  width: 190px;
}

.img1 {
  max-width: 100%;
  object-fit: contain;
  height: 220px;
  width: 220px;
}

.img3 {
  max-width: 100%;
  object-fit: contain;
  height: 240px;
  width: 240px;
}

.image3 {
  height: 240px;
  width: 240px;

}

.img2 {
  max-width: 100%;
  object-fit: contain;
  height: 600px;
  width: 600px;
}

.image2 {
  height: 600px;
  width: 600px;
}

@media (max-width: 757px) and (min-width: 1024px) {
  .card{
    width: 100%;
  }
  
}

@media(min-width: 1024px) and (max-width: 1300px) {
  .haiding {
    color:  #f28aee;;
    font-weight: 600;
    font-size: 2.4rem;
    letter-spacing: 0.3px;
    margin-bottom: 2rem;
  }
  
  
  .detail {
    color: white;
    font-size: 1rem;
    margin-bottom: 2rem;
    font-size: 1.3rem;
    font-weight: 100 !important;
  }

.image2 {
  height: 250px;
  width: 250px;
}


}


@media(max-width:756px) {
  .card {
    grid-template-columns: 1fr;
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  .image2 {
    height: 400px;
    width: 400px;
  }

 .img2 {
    width: 400px;
    height: 400px;
  }

  
  .left {

    max-width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    padding: 2rem 1rem;
    gap: 2rem;

  }

  .right {

    margin: 0;
    padding: 0;
    padding: 0rem 1rem;
    padding-bottom: 2rem;
  }

  .action {
    flex-direction: column;
    margin: 0;
    padding: 0;
    gap: 0;
    gap: 1rem;
  }

  .action1,
  .action2 {
    width: 100%;
    margin: 0;
    padding: 0.8rem;
    font-size: 0.8rem;

  }

  .haiding {
    font-size: 1.8rem;
    margin: 0;
    padding: 0;
  }

  .detail {
    font-size: 1rem;
    margin: 0;
    padding: 0;
  }

  .detail2 {
    font-size: 0.5rem;
    margin: 0;
    padding: 0;
  }
}


.card {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0rem;
    background-color: #000000;
    place-items: center;
    
  }
  
  .left,
  .right {
    width: 100%;
      padding: 3rem 3rem;
  }
  
  
  
  .right{
      display: flex;
      align-items: center;
      flex-direction: column;
      
  }
  
  .inner{
      display: flex;
      align-items: center;
      margin-top: -5rem;
  }
  
  
  .left{
      display: flex;
      flex-direction: column;
    padding-left: 8.5rem;
  }
  
  .haiding {
    color:  #f28aee;;
    font-weight: 600;
    font-size: 2.8rem; 
    letter-spacing: 0.3px;
    margin-bottom: 2rem; 
   
  }
  
  
  .detail {
    color: white;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 2rem;
    font-size: 1.8rem;
    font-weight: 100 !important;
   
  }
  
  .action {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 2rem;
   
  }
  
  .action1 {
    
    background-color: #f28aee;
    padding: 1rem;
    display: block;
    width: 50%;
    border-radius: 10px;
    border: none;
    outline: none;
    font-size: 1.1rem;
    font-weight: 400;
    font-family: inherit;
    text-align: center;
  }
  
  .action2 {
    border: none;
    outline: none;
    padding: 1rem ;
    border-radius: 10px;
    background-color: white;
    color: black;
    width: 50%;
    display: block;
      font-size: 1.1rem;
      font-weight: 400;
      font-family: inherit;
  }
  
  .action1,
  .action2:hover {
    cursor: pointer;
  }
  
  .img2 {
    max-width: 100%;
    object-fit: contain;
    height: 560px;
    width: 560px;
  }
  
  .image2 {
    height: 560px;
    width: 560px;
  }

  @media (min-width: 1024px) and (max-width: 1300px) {
    .haiding {
      color:  #f28aee;;
      font-weight: 600;
      font-size: 2.4rem;
      letter-spacing: 0.3px;
      margin-bottom: 2rem;
    }
    
    .detail {
      color: white;
      font-size: 1rem;
      margin-bottom: 2rem;
      font-size: 1.3rem;
      font-weight: 100 !important;
    }
    .image2 {
      height: 480px;
      width: 480px;
    }
    
  }
  
  
  
  @media(max-width:756px){
      .card{
          grid-template-columns: 1fr;
          padding-top: 2rem !important;
          padding-bottom: 2rem !important;
      }
  
      .img2 {
        max-width: 100%;
        object-fit: contain;
        height: 200px;
        width: 200px;
      }
      
      .image2 {
        height: 200px;
        width: 200px;
      }
  
      .left{
          max-width: 100%;
          padding: 0;
          margin: 0;
          overflow: hidden;
          padding: 2rem 1rem;
          gap: 2rem;        
      }
  
      .right{
         
          margin: 0;
          padding: 0;
          padding: 0rem 1rem;
          padding-bottom: 2rem;
      }
      .action{
          flex-direction: column;
          margin: 0;
          padding: 0;
          gap: 0;
          gap: 1rem;
      }
      .action1,.action2{
          width: 100%;
          margin: 0;
          padding: 0.8rem;
          font-size: 0.8rem;
      
      }
      .haiding{
          font-size: 2rem;
          margin: 0;
          padding: 0;
      }
      .detail{
          font-size: 1rem;
          margin: 0;
          padding: 0;
          word-wrap: break-word;
          overflow-wrap: break-word;
      }
  }